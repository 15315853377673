@font-face {
  font-family: 'DINNextLTPro';
  src: url('./assets/fonts/din-next-lt-pro-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  & a {
    text-decoration: none;
    color: #000E6E;
  }
}

body {
  overflow-x: hidden;
  font-family: 'DINNextLTPro', sans-serif;
}

.faqra-club-section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('./assets/img/faqraclub-bg-img.png') no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  justify-content: center;
  /* Vertically center content */
  align-items: center;
}

header,
footer {
  width: 100%;
  /* Ensure header and footer span the width of the container */
}

header {
  padding-top: 50px !important;
  position: absolute;
  top: 0%;
  text-align: center;
  padding: 20px 0;
}

header img {
  width: 130px;
}

.faqra-club-section-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

& h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 50px;
}

.download-icons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.download-icons img {
  width: 30px;
}

.download-icons span {
  color: #ffffff;
  font-size: 20px;
}

footer {
  padding: 20px 0;
  position: absolute;
  bottom: 0%;
  width: 100%;
}

.footer-part-1 {
  align-items: center;
  justify-content: left;
}

.footer-part-2 {
  align-items: center;
  justify-content: end;
}

.contect-icons {
  display: flex;
  gap: 10px;
  align-items: center;
  border-right: 1px solid #ffffff;
  padding-right: 10px;
}

.contect-icons .icon {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 33px;
  border-radius: 50%;
  background: #ffffff;
}

.contect-icons .wp-icon {
  line-height: 31px;
  font-size: 23px;
}

.contect-number p {
  & a{
    color: #ffffff !important;
  }
  margin-top: 18px;
  font-size: 18px;
}

.stay-connected p {
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 18px;
  font-size: 18px;
}

.social-icons {
  display: flex;
  gap: 10px;
  align-items: center;
  border-left: 1px solid #ffffff;
  padding-left: 10px;
}

.social-icons .icon {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  background: #ffffff;
}


@media (max-width: 767px) {
  .footer-part-1 {
    justify-content: center;
  }

  .footer-part-2 {
    align-items: cnter;
    justify-content: center;
  }
  & h1 {
    font-size: 50px;
  }
}


@media (max-width: 600px) {
& h1 {
  font-size: 50px;
}
}